.home-page--container {
  grid-template-rows: min-content auto min-content;
  display: grid;
  position: relative;
  min-height: 100% !important;
}

.home-page--container:before {
  content: "";
  z-index: -1;
  opacity: .25;
  background-image: url("homeLTR.63c65a4c.jpg");
  background-position: 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.section-home--main {
  flex-direction: column;
  font-weight: 500;
  display: flex;
  width: 100% !important;
}

.coloured--section {
  width: 100%;
}

.title-container--home {
  text-align: center;
  background-color: #fdf9f580;
  border-radius: .8rem;
  flex-direction: column;
  justify-content: center;
  gap: 2.8rem;
  width: 75%;
  height: fit-content;
  margin: 5% auto;
  padding: 5% 1%;
  display: flex;
  position: relative;
  box-shadow: 0 0 .6rem .05rem #bbb;
  color: #333 !important;
}

.title-container--home h1 {
  font-size: 3.2rem;
  font-weight: 600;
}

.dedication {
  font-size: 2.1rem;
  font-weight: 500;
}

.explained--text {
  text-align: center;
  grid-template-columns: 1fr;
  width: 80%;
  margin: 0 auto;
  font-weight: 400;
  display: grid;
}

.statement {
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  font-size: 1.5rem;
  display: flex;
}

.text-container--home {
  grid-template-columns: 1fr;
  display: grid;
}

.pThree, .pFive {
  width: 80%;
}

.box--container {
  align-items: start;
  gap: 3.6rem;
  width: 85%;
  margin: 0 auto;
  display: flex;
}

.box {
  flex-direction: column;
  gap: 2.4rem;
  min-height: 40vh;
  display: flex;
}

.home--text-box {
  gap: 1.8rem;
  width: 80%;
  display: flex;
}

.home--text-box.second {
  width: 100%;
}

.action--box:link, .action--box:visited {
  text-align: center;
  text-shadow: none;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 50%;
  min-height: 50%;
  padding: 1rem;
  transition: all .3s;
  display: flex;
  box-shadow: 0 0 1rem .2rem #0000001a;
}

.action--box:hover, .action--box:active {
  background-color: #a1000b;
}

.ph-globe-hemisphere-east, .ph-music-notes {
  font-size: 2.4rem;
}

h2 {
  color: #a1000b;
}

.quote {
  align-items: center;
  width: 85%;
  height: 15rem;
  margin: 0 auto;
  font-family: montserrat, sans-serif;
  font-size: 1.8rem;
  font-style: italic;
  display: flex;
}

.quote--two {
  align-items: center;
  display: flex;
}
/*# sourceMappingURL=home.05e35d8f.css.map */
