.home-page--container {
  position: relative;
  display: grid;
  grid-template-rows: min-content auto min-content;
  min-height: 100% !important;
}

.home-page--container::before {
  content: "";
  position: fixed;
  z-index: -1;
  opacity: 25%;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("../imgs/homeLTR.jpg");
  background-size: cover;
  background-position: left;
}

.section-home--main {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  /* text-shadow: white 1rem 0 2rem; */
  width: 100% !important;
}

.coloured--section {
  width: 100%;
}

.title-container--home {
  /* background-image: url("../imgs/homeLTR.jpg"); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* filter: grayscale(90%); */
  box-shadow: 0rem 0rem 0.6rem 0.05rem #bbb;
  border-radius: 0.8rem;
  background-color: rgb(253, 249, 245, 0.5);
  color: #333 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 75%;
  height: fit-content;
  /* min-height: calc(vh - 7rem); */
  /* margin: 0 auto; */
  position: relative;
  padding: 5% 1%;
  margin: 5% auto;

  /* padding */
  gap: 2.8rem;
}

.title-container--home h1 {
  font-size: 3.2rem;
  font-weight: 600;
}

.dedication {
  font-size: 2.1rem;
  font-weight: 500;
}

.explained--text {
  font-weight: 400;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  margin: 0 auto;
  width: 80%;
}

.statement {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
}

.text-container--home {
  display: grid;
  grid-template-columns: 1fr;
}

.pOne {
}

.pThree,
.pFive {
  width: 80%;
}

.box--container {
  display: flex;
  gap: 3.6rem;
  align-items: start;
  width: 85%;
  margin: 0 auto;
  /* background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0rem 0rem 0.6rem 0.1rem #999; */
}

.box {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  min-height: 40vh;
}

.home--text-box {
  width: 80%;
  display: flex;
  gap: 1.8rem;
}

.home--text-box.second {
  width: 100%;
}

.action--box:link,
.action--box:visited {
  box-shadow: 0rem 0rem 1rem 0.2rem rgb(0, 0, 0, 0.1);
  text-align: center;
  text-shadow: none;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 50%;
  min-height: 50%;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  transition: all 0.3s ease;
}

.action--box:hover,
.action--box:active {
  background-color: #a1000b;
}

.ph-globe-hemisphere-east,
.ph-music-notes {
  font-size: 2.4rem;
}

h2 {
  color: #a1000b;
}
.quote {
  font-size: 1.8rem;
  font-style: italic;
  font-family: "montserrat", sans-serif;
  height: 15rem;
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.quote--two {
  display: flex;
  align-items: center;
}
